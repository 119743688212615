import { initializeGeocoder, initializeSourceMarker, selectRegion } from './googlemaps';
import { loader } from './googlemaps-loader';

window.initializeInstitutionForm = () => {
  loader
    .load()
    .then(() => {
      initializeGeocoder('map-canvas');
      initializeSourceMarker(
        'institution-latitude',
        'institution-longitude',
        'institution-civicNumber',
        'institution-street',
        'institution-city',
        'institution-province',
        null,
        'institution-position',
        'Canada',
      );
    })
    .catch((e) => {
      console.debug(e);
    });

  $('#institution-region').change(() => {
    selectRegion('institution-region', 'institution-city', 'institution-province', '/app/api/region');
  });
};
