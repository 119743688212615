import generator from 'generate-password-browser';

$('#generate-password').click(function (event) {
  event.preventDefault();
  const password = generator.generate({
    length: 8,
    numbers: true,
    symbols: true,
    lowercase: true,
    uppercase: true,
    strict: true,
  });
  $('#password').val(password);
  $('#confirmedPassword').val(password);
  navigator.clipboard.writeText(password);
});
