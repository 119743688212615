import { addTeamMarkers, addTransportRequestMarker, initializeAssignmentMap, initializeGeocoder } from './googlemaps';
import { loader } from './googlemaps-loader';
import { initializePageRefresh } from './nez-rouge';

window.initializeTransportAssignment = (transportRequest, teams) => {
  loader
    .load()
    .then(() => {
      initializeGeocoder('map-big-canvas');
      initializeAssignmentMap();

      addTeamMarkers(teams, transportRequest);
      addTransportRequestMarker(transportRequest);
    })
    .catch((e) => {
      console.debug(e);
    })
    .finally(() => {
      initializePageRefresh(document);
    });
};
