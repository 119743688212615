const setCheckbox = (checkboxElementId, value) => {
  $(`#${checkboxElementId}`).attr('checked', value);
};

const isElementChecked = (elementId) => {
  return $(`#${elementId}`).is(':checked');
};

const areCookiesSupported = () => {
  const testCookie = 'test_cookie';
  Cookies.set(testCookie, true);
  if (Cookies.get(testCookie)) {
    Cookies.remove(testCookie);
    return true;
  }

  return false;
};

window.setTimezoneCookie = () => {
  const timezoneCookie = 'timezone_offset';

  if (!Cookies.get(timezoneCookie)) {
    if (areCookiesSupported()) {
      Cookies.set(timezoneCookie, new Date().getTimezoneOffset());
    }
  }
};

export const confirmDialog = (confirmationMessage) => {
  // eslint-disable-next-line no-alert
  return window.confirm(confirmationMessage);
};

export const toggleDivFromSelectorValue = (divClassSelector, selectorId, shownOptionValue) => {
  const selectedOption = $(`${selectorId} option:selected`).val();

  const divElement = $(divClassSelector);
  if (selectedOption === shownOptionValue) {
    divElement.show();
  } else {
    divElement.hide();
  }
};
window.toggleDivFromSelectorValue = toggleDivFromSelectorValue;

export const toggleAvailabilitySelectors = () => {
  toggleDivFromSelectorValue('#team-location-div', '#status-selector', 'AVAILABLE_IN_LOCATION');
  toggleDivFromSelectorValue('#team-region-div', '#status-selector', 'AVAILABLE_IN_REGION');
  toggleDivFromSelectorValue('#team-institution-div', '#status-selector', 'AVAILABLE_IN_INSTITUTION');
  toggleDivFromSelectorValue('#team-coordinates-div', '#status-selector', 'AVAILABLE_AT_COORDINATES');
};
window.toggleAvailabilitySelectors = toggleAvailabilitySelectors;

export const toggleAvailabilitySelectorsByDivClass = (divClassSelector) => {
  toggleDivFromSelectorValue(
    `.team-location.${divClassSelector}`,
    `#status-selector.${divClassSelector}`,
    'AVAILABLE_IN_LOCATION',
  );
  toggleDivFromSelectorValue(`.team-region.${divClassSelector}`, `#status-selector.${divClassSelector}`, 'AVAILABLE_IN_REGION');
  toggleDivFromSelectorValue(
    `.team-institution.${divClassSelector}`,
    `#status-selector.${divClassSelector}`,
    'AVAILABLE_IN_INSTITUTION',
  );
};
window.toggleAvailabilitySelectorsByDivClass = toggleAvailabilitySelectorsByDivClass;

const resolveVolunteerIdInLink = (volunteerSelectorId, baseUrl) => {
  let resolvedUrl = null;
  const volunteerFieldElement = $(`#${volunteerSelectorId}`);
  const selectedVolunteerId = volunteerFieldElement.val();
  if (selectedVolunteerId) {
    resolvedUrl = baseUrl.replace('[0]', selectedVolunteerId);
  }
  return resolvedUrl;
};
window.resolveVolunteerIdInLink = resolveVolunteerIdInLink;

export const setVolunteerIdAndTeamNumberInLink = (teamNumberElementId, volunteerSelectorId, volunteerLinkElementId, baseUrl) => {
  let resolvedUrl = resolveVolunteerIdInLink(volunteerSelectorId, baseUrl);
  if (resolvedUrl) {
    const teamNumberFieldElement = $(`#${teamNumberElementId}`);
    const teamNumber = teamNumberFieldElement.val();
    if (teamNumber) {
      resolvedUrl = resolvedUrl.replace('[1]', teamNumber);
      $(`#${volunteerLinkElementId}`).attr('href', resolvedUrl);
      return true;
    }
  }
  return false;
};
window.setVolunteerIdAndTeamNumberInLink = setVolunteerIdAndTeamNumberInLink;

export const setVolunteerIdInLink = (volunteerSelectorId, volunteerLinkElementId, baseUrl) => {
  const resolvedUrl = resolveVolunteerIdInLink(volunteerSelectorId, baseUrl);
  if (resolvedUrl) {
    $(`#${volunteerLinkElementId}`).attr('href', resolvedUrl);
    return true;
  }
  return false;
};
window.setVolunteerIdInLink = setVolunteerIdInLink;

export const submitActionAfterConfirmation = (confirmationMessage, action, formId) => {
  if (confirmDialog(confirmationMessage)) {
    $(`#${formId}`).attr('action', action);
    return true;
  }

  return false;
};
window.submitActionAfterConfirmation = submitActionAfterConfirmation;

export const checkRefundPaidAfterConfirmation = (
  refundPaidElementId,
  confirmationMessage,
  refundTotalElementId,
  noTotalMessage,
) => {
  if ($(`#${refundTotalElementId}`).val().length === 0) {
    // eslint-disable-next-line no-alert
    window.alert(noTotalMessage);
    setCheckbox(refundPaidElementId, false);
  } else if (isElementChecked(refundPaidElementId)) {
    if (!confirmDialog(confirmationMessage)) {
      setCheckbox(refundPaidElementId, false);
    }
  }
};
window.checkRefundPaidAfterConfirmation = checkRefundPaidAfterConfirmation;

export const submitAction = (action, formId) => {
  $(`#${formId}`).attr('action', action);
  return true;
};
window.submitAction = submitAction;

export const submitForm = (url, formId, method) => {
  const previousMethod = $(`#${formId}`).attr('method');
  if (method) {
    $(`#${formId}`).attr('method', method);
  }
  const previousUrl = $(`#${formId}`).attr('action');
  $(`#${formId}`).attr('action', url);
  $(`#${formId}`).submit();
  // Reset form action to what it was before
  $(`#${formId}`).attr('action', previousUrl);
  $(`#${formId}`).attr('method', previousMethod);
};
window.submitForm = submitForm;

export const initializeTotalMileage = (vehicleMileageStartId, vehicleMileageEndId, vehicleMileageTotalId) => {
  const startingMileage = $(`#${vehicleMileageStartId}`).val();
  const endingMileage = $(`#${vehicleMileageEndId}`).val();

  if (!Number.isNaN(startingMileage) && !Number.isNaN(endingMileage) && startingMileage.length > 0 && endingMileage.length > 0) {
    $(`#${vehicleMileageTotalId}`).val(endingMileage - startingMileage);
  } else {
    $(`#${vehicleMileageTotalId}`).val('');
  }
};
window.initializeTotalMileage = initializeTotalMileage;

export const calculateTotalMileage = (vehicleMileageStartId, vehicleMileageEndId, vehicleMileageTotalId, refundTotalId) => {
  initializeTotalMileage(vehicleMileageStartId, vehicleMileageEndId, vehicleMileageTotalId);
  $(`#${refundTotalId}`).val('');
};
window.calculateTotalMileage = calculateTotalMileage;

export const calculateGasRefundTotal = (
  refundType,
  mileageTotalId,
  refundUnitAmountId,
  refundRatioLitresId,
  refundTotalId,
  cannotCalculateMessage,
) => {
  const mileage = $(`#${mileageTotalId}`).val();
  const gasUnitAmount = $(`#${refundUnitAmountId}`).val();
  const refundRatioLitres = $(`#${refundRatioLitresId}`).val();

  if (
    !Number.isNaN(mileage) &&
    !Number.isNaN(gasUnitAmount) &&
    mileage.length > 0 &&
    gasUnitAmount.length > 0 &&
    (refundType !== 'LITRES' || (refundRatioLitres.length > 0 && !Number.isNaN(refundRatioLitres)))
  ) {
    if (refundType === 'LITRES') {
      const refundTotal = ((refundRatioLitres * mileage) / 100) * gasUnitAmount;
      $(`#${refundTotalId}`).val(Math.round(refundTotal * 100) / 100);
    } else {
      $(`#${refundTotalId}`).val(mileage * gasUnitAmount);
    }
  } else {
    // eslint-disable-next-line no-alert
    window.alert(cannotCalculateMessage);
    $(`#${refundTotalId}`).val('');
  }
};
window.calculateGasRefundTotal = calculateGasRefundTotal;

export const allowRefundTotalSubmission = (refundPaidElementId, redundTotalElementId) => {
  if (isElementChecked(refundPaidElementId)) {
    $(`#${redundTotalElementId}`).removeAttr('readonly');
  }
  return true;
};
window.allowRefundTotalSubmission = allowRefundTotalSubmission;

const REFRESH_PAGE_TIMEOUT = 30000;

export const initializePageRefresh = (pageDocument) => {
  let time = new Date().getTime();
  $(pageDocument.body).on('click keypress', () => {
    time = new Date().getTime();
  });

  const refresh = () => {
    if (new Date().getTime() - time >= REFRESH_PAGE_TIMEOUT) window.location.reload(true);
    else setTimeout(refresh, 10000);
  };

  setTimeout(refresh, 10000);
};
window.initializePageRefresh = initializePageRefresh;

export const removeOffenseContent = (dateElementId, descriptionElementId, disclosureDateElementId, locationElementId) => {
  $(`#${dateElementId}`).val('');
  $(`#${descriptionElementId}`).val('');
  $(`#${disclosureDateElementId}`).val('');
  $(`#${locationElementId}`).val('');
};
window.removeOffenseContent = removeOffenseContent;

const closeOnSelect = (select2Element) => {
  $(document).ready(() => {
    $(select2Element).select2({
      closeOnSelect: false,
    });
  });
};
window.closeOnSelect = closeOnSelect;
