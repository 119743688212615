$(() => {
  // navbar notification popups
  $('.notification-dropdown').each(function initializeDropdown(_index, el) {
    const $el = $(el);
    const $dialog = $el.find('.pop-dialog');
    const $trigger = $el.find('.trigger');

    $dialog.click(function click(e) {
      e.stopPropagation();
    });
    $dialog.find('.close-icon').click(function click(e) {
      e.preventDefault();
      $dialog.removeClass('is-visible');
      $trigger.removeClass('active');
    });
    $('body').click(function click() {
      $dialog.removeClass('is-visible');
      $trigger.removeClass('active');
    });

    $trigger.click(function click(e) {
      e.preventDefault();
      e.stopPropagation();

      // hide all other pop-dialogs
      $('.notification-dropdown .pop-dialog').removeClass('is-visible');
      $('.notification-dropdown .trigger').removeClass('active');

      $dialog.toggleClass('is-visible');
      if ($dialog.hasClass('is-visible')) {
        $(this).addClass('active');
      } else {
        $(this).removeClass('active');
      }
    });
  });

  // skin changer
  $('.skins-nav .skin').click(function click(e) {
    e.preventDefault();
    if ($(this).hasClass('selected')) {
      return;
    }
    $('.skins-nav .skin').removeClass('selected');
    $(this).addClass('selected');

    if (!$('#skin-file').length) {
      $('head').append('<link rel="stylesheet" type="text/css" id="skin-file" href="">');
    }
    const $skin = $('#skin-file');
    if ($(this).attr('data-file')) {
      $skin.attr('href', $(this).data('file'));
    } else {
      $skin.attr('href', '');
    }
  });

  // sidebar menu dropdown toggle
  $('.dashboard-menu .dropdown-toggle').click(function click(e) {
    e.preventDefault();
    const $item = $(this).parent();
    $item.toggleClass('active');
    if ($item.hasClass('active')) {
      $item.find('.submenu').slideDown('fast');
    } else {
      $item.find('.submenu').slideUp('fast');
    }
  });

  // mobile side-menu slide toggler
  const $menu = $('#sidebar-nav');
  $('body').click(function click() {
    if ($menu.hasClass('display')) {
      $menu.removeClass('display');
    }
  });
  $menu.click(function click(e) {
    e.stopPropagation();
  });
  $('#menu-toggler').click(function click(e) {
    e.stopPropagation();
    $menu.toggleClass('display');
  });

  // build all tooltips from data-attributes
  $("[data-toggle='tooltip']").each(function click(_index, el) {
    $(el).tooltip({
      placement: $(this).data('placement') || 'top',
    });
  });

  // custom uiDropdown element, example can be seen in user-list.html on the 'Filter users' button
  const UiDropdown = new (function drowpdownConstructor() {
    const self = this;
    this.hideDialog = ($el) => {
      return $el.find('.dialog').hide().removeClass('is-visible');
    };
    this.showDialog = ($el) => {
      return $el.find('.dialog').show().addClass('is-visible');
    };
    this.initialize = () => {
      $('html').click(function click() {
        $('.ui-dropdown .head').removeClass('active');
        return self.hideDialog($('.ui-dropdown'));
      });
      $('.ui-dropdown .body').click(function click(e) {
        return e.stopPropagation();
      });
      return $('.ui-dropdown').each(function click(_index, el) {
        return $(el).click(function itemClick(e) {
          e.stopPropagation();
          $(el).find('.head').toggleClass('active');
          if ($(el).find('.head').hasClass('active')) {
            return self.showDialog($(el));
          }
          return self.hideDialog($(el));
        });
      });
    };
    return this.initialize;
  })();

  // instantiate new uiDropdown from above to build the plugins
  // eslint-disable-next-line no-new
  new UiDropdown();

  // toggle all checkboxes from a table when header checkbox is clicked
  $('.table th input:checkbox').click(function click() {
    const $checks = $(this).closest('.table').find('tbody input:checkbox');
    if ($(this).is(':checked')) {
      $checks.prop('checked', true);
    } else {
      $checks.prop('checked', false);
    }
  });

  // ------- nez rouge specific stuff starts here --------

  // select2 plugin for select elements
  $('.select2').select2({
    placeholder: 'Replace Me',
  });

  // select2 plugin for select elements
  $('.select2-allowClear').select2({
    placeholder: 'Replace Me',
    allowClear: true,
  });

  $('.select2-noSearch').select2({
    minimumResultsForSearch: 10,
  });

  $('.datepicker-top')
    .datepicker({
      orientation: 'top',
      keyboardNavigation: false,
    })
    .on('changeDate', function changeDate() {
      $(this).datepicker('hide');
    });

  $('.datepicker-bottom')
    .datepicker({
      orientation: 'bottom',
      keyboardNavigation: false,
    })
    .on('changeDate', function changeDate() {
      $(this).datepicker('hide');
    });

  $('#central-action-link').tooltip({
    placement: 'bottom',
  });
});

// eslint-disable-next-line import/prefer-default-export
export const registerSearchableSelector = (type, selectorElementId, queryPayloadFunc, renderingFunc, initFunc) => {
  if (queryPayloadFunc === undefined) {
    // eslint-disable-next-line no-param-reassign
    queryPayloadFunc = (term) => {
      return {
        selectableSearchValue: term,
      };
    };
  }

  if (renderingFunc === undefined) {
    // eslint-disable-next-line no-param-reassign
    renderingFunc = (data) => {
      return {
        id: data.id,
        text: data.selectableValue,
      };
    };
  }

  const baseApiUrl = `/app/api/${type}`;

  $(`#${selectorElementId}`).select2({
    placeholder: 'Replace Me',
    allowClear: true,
    minimumInputLength: 2,

    ajax: {
      url: baseApiUrl,
      dataType: 'json',
      data: queryPayloadFunc,

      results(data) {
        const values = [];
        if (data && data.length) {
          $.each(data, (_index, value) => {
            const item = renderingFunc(value);
            values.push(item);
          });
        }
        return {
          results: values,
        };
      },
    },

    initSelection(element, callback) {
      const id = $(element).val();
      if (id !== '') {
        $.ajax(`${baseApiUrl}/${id}`, {
          dataType: 'json',
        }).done((data) => {
          const result = renderingFunc(data);

          if (initFunc !== undefined) {
            initFunc(result);
          }

          callback(result);
        });
      }
    },
  });
};

window.registerSearchableSelector = registerSearchableSelector;

// jQuery plugin to prevent double submission of forms
jQuery.fn.preventDoubleSubmission = () => {
  $(this).on('submit', function submit(e) {
    const $form = $(this);

    if ($form.data('submitted') === true) {
      // Previously submitted - don't submit again
      e.preventDefault();
    } else {
      // Mark it so that the next submit can be ignored
      $form.data('submitted', true);
    }
  });

  // Keep chainability
  return this;
};

$('form').preventDoubleSubmission();
