import { registerSearchableSelector } from './theme';
import {
  toggleAvailabilitySelectors,
  toggleDivFromSelectorValue,
  initializeTotalMileage,
  allowRefundTotalSubmission,
  confirmDialog,
  initializePageRefresh,
} from './nez-rouge';

function registerSearchableTeamMemberSelectors(typeSelectorElementId, organizationSelectorElementId) {
  let selectedOrganizationId = null;
  const selectedType = $(`#${typeSelectorElementId}`).val();
  if (selectedType === 'ORGANIZATION') {
    selectedOrganizationId = $(`#${organizationSelectorElementId}`).val();
  }

  registerSearchableSelector('volunteer', 'select2-volunteer-escort', (term) => {
    return {
      selectableSearchValue: term,
      organizationId: selectedOrganizationId,
      isEscort: true,
      filter: 'Active',
    };
  });
  registerSearchableSelector('volunteer', 'select2-volunteer-driver', (term) => {
    return {
      selectableSearchValue: term,
      organizationId: selectedOrganizationId,
      isDriver: true,
      filter: 'Active',
    };
  });
  registerSearchableSelector('volunteer', 'select2-volunteer-partner', (term) => {
    return {
      selectableSearchValue: term,
      organizationId: selectedOrganizationId,
      isPartner: true,
      filter: 'Active',
    };
  });
}

window.initializeTeamForm = () => {
  registerSearchableTeamMemberSelectors('type-selector', 'select2-organization');

  function submitTeamPreparation() {
    const btn = $(':input[clicked=activate]');
    if (btn.size() > 0) {
      $('#activateOnSave').val('true');
    }
    return allowRefundTotalSubmission('vehicle-mileage-refund-total');
  }

  $(document).ready(() => {
    toggleAvailabilitySelectors();
    toggleDivFromSelectorValue('#team-organization-div', '#type-selector', 'ORGANIZATION');
    initializeTotalMileage('vehicle-mileage-start', 'vehicle-mileage-end', 'vehicle-mileage-total');
    $('#main-form').submit(submitTeamPreparation);
  });

  $('#submitAndActivate').click(function click() {
    $(this).attr('clicked', 'activate');
  });

  $('[data-phone-number][data-volunteer-id]').each(function each() {
    $.ajax({
      url: `/app/api/volunteer/${$(this).data('volunteerId')}`,
      dataType: 'json',
    }).done((data) => {
      const label = $(this).data('placeholder');
      $(this).text(`${label} - ${data.cellNumber}`);
    });
  });

  registerSearchableSelector(
    'organization',
    'select2-organization',
    (term) => {
      return {
        name: term,
      };
    },
    (value) => {
      return {
        id: value.id,
        text: value.name,
      };
    },
  );

  registerSearchableSelector(
    'institution',
    'institution-select',
    (term) => {
      return {
        name: term,
        limit: 50,
      };
    },
    (value) => {
      return {
        id: value.id,
        text: value.name,
      };
    },
  );

  function resetTeamMembers() {
    $('#select2-volunteer-escort').select2('data', null);
    $('#select2-volunteer-driver').select2('data', null);
    $('#select2-volunteer-partner').select2('data', null);
  }

  window.applyOrganizationId = (event, typeSelectorElementId, organizationSelectorElementId, confirmationMessage) => {
    if (confirmDialog(confirmationMessage)) {
      resetTeamMembers();
    } else {
      $(`#${organizationSelectorElementId}`).select2('val', event.removed ? event.removed.id : '');
    }
    registerSearchableTeamMemberSelectors(typeSelectorElementId, organizationSelectorElementId);
  };

  window.changeTeamType = (
    teamOrganizationDivElementId,
    typeSelectorElementId,
    organizationSelectorElementId,
    organizationExpectedDateElementId,
  ) => {
    $(`#${organizationSelectorElementId}`).select2('val', '');
    $(`#${organizationExpectedDateElementId}`).val('');
    toggleDivFromSelectorValue(`#${teamOrganizationDivElementId}`, `#${typeSelectorElementId}`, 'ORGANIZATION');
    registerSearchableTeamMemberSelectors(typeSelectorElementId, organizationSelectorElementId);
  };
};

window.initializeTeamList = () => {
  $(document).ready(() => {
    toggleDivFromSelectorValue('#organization-filter-div', '#filter-type', 'ORGANIZATION');
  });

  registerSearchableSelector(
    'organization',
    'select2-organization',
    (term) => {
      return {
        name: term,
      };
    },
    (value) => {
      return {
        id: value.id,
        text: value.name,
      };
    },
  );
  initializePageRefresh(document);
};
