import once from 'lodash/once';
import { initializeGeocoder, initializeSourceMarker, initializeTargetMarker } from './googlemaps';
import { loader } from './googlemaps-loader';
import { registerSearchableSelector } from './theme';
import { sortAlphabetically } from './sort-select-options';

const initializeMap = once(() => {
  initializeGeocoder('map-canvas');
  initializeSourceMarker(
    'origin-latitude',
    'origin-longitude',
    null,
    'origin-streetAddress',
    'origin-city',
    'origin-province',
    'origin-postalCode',
    'origin-position',
    'Canada',
  );
  initializeTargetMarker(
    'destination-latitude',
    'destination-longitude',
    null,
    'destination-streetAddress',
    'destination-city',
    'destination-province',
    'destination-postalCode',
    'destination-position',
    'Canada',
  );
});
const phoneNumber = $('#phoneNumber');

const institutionSelect = $('#institution-select');

const originAddress = $('#origin-address');
const institutionAddress = $('#institution-address');

const institutionName = $('#institution-name');
const institutionStreetAddress = $('#institution-street-address');
const regionAddress = $('#region-address');

const streetAddress = $('#origin-streetAddress');
const city = $('#origin-city');
const province = $('#origin-province');
const postalCode = $('#origin-postalCode');

function applyInstitutionChange(institution) {
  const hasInstitution = !!institutionSelect.val();

  if (hasInstitution) {
    if (!phoneNumber.val()) {
      phoneNumber.val(institution.phoneNumber);
    }

    institutionName.text(institution.text);
    institutionStreetAddress.text(institution.streetAddress);

    streetAddress.val(institution.streetAddress);
    postalCode.val('');

    regionAddress.text('...');

    $.ajax(`/app/api/region/${institution.regionId}`, {
      dataType: 'json',
    }).done((data) => {
      regionAddress.text(`${data.city}, ${data.province}`);

      city.val(data.city);
      province.val(data.province);

      $('#origin-position').click();
    });
  } else {
    phoneNumber.val('');
    streetAddress.val('');
    city.val('');
    province.val('');
    postalCode.val('');
  }

  originAddress.toggle(!hasInstitution);
  institutionAddress.toggle(hasInstitution);
}

institutionSelect.change((event) => {
  applyInstitutionChange(event.added);
});

registerSearchableSelector(
  'institution',
  'institution-select',
  (term) => ({
    name: term,
  }),
  (value) => ({
    id: value.id,
    text: value.name,
    streetAddress: `${value.civicNumber}, ${value.street}`,
    regionId: value.regionId,
    phoneNumber: value.phoneNumber,
  }),
  applyInstitutionChange,
);

export const transferDonationToModal = () => {
  $('.completion-donation').val($('#donation').val());
  $('.completion-hasReceipt').prop('checked', $('#hasReceipt').prop('checked'));
};
window.transferDonationToModal = transferDonationToModal;

function processCompletionResponse(action, response) {
  if (response.success) {
    window.location.href = `/app/transport/${response.transportRequestId}/assignment/${action}/success`;
  } else {
    $(`#complete-error-message.${action}`).text(response.errorMessage);
    $(`#complete-error-container.${action}`).show();
  }
}

export const submitCompletion = (action, formSelector, url, ajaxError) => {
  const form = $(`#transport-completion-modal-form.${formSelector}`);
  $.ajax({
    url,
    type: 'POST',
    data: form.serialize(),
    success(response) {
      processCompletionResponse(action, response);
    },
    error() {
      $(`#complete-error-message.${action}`).text(decodeURIComponent(ajaxError));
      $(`#complete-error-container.${action}`).show();
    },
  });
};
window.submitCompletion = submitCompletion;

export const closeCompletionErrorBox = (action) => {
  $(`#complete-error-container.${action}`).hide();
};
window.closeCompletionErrorBox = closeCompletionErrorBox;

$('#createAndDuplicate').click(function click() {
  $(this).attr('clicked', 'duplicate');
});

$('#updateAndDuplicate').click(function click() {
  $(this).attr('clicked', 'duplicate');
});

const submitTransportPreparation = () => {
  const btn = $(':input[clicked=duplicate]');
  if (btn.size() > 0) {
    $('#duplicateOnSave').val('true');
  }
  return true;
};

$(() => {
  $('#main-form input').keypress((e) => {
    if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
      $('input[type=submit].default-save').click();
      return false;
    }
    return true;
  });
});

$(document).ready(() => {
  $('#main-form').submit(submitTransportPreparation);
});

window.toggleMapInForm = () => {
  $('#map-canvas').toggleClass('not-visible');
};

window.initializeTransportForm = () => {
  ['complete', 'unfindable', 'cancel', 'reassign', 'reactivate'].forEach((modalStatus) => {
    registerSearchableSelector(
      'institution',
      `completion-institution-selector-${modalStatus}`,
      (term) => ({
        name: term,
        limit: 50,
      }),
      (value) => ({
        id: value.id,
        text: value.name,
      }),
    );
  });

  sortAlphabetically($('.transport-request-vehicle-manufacturer-option'));
  sortAlphabetically($('.transport-request-vehicle-type-option'));
  sortAlphabetically($('.transport-request-vehicle-color-option'));

  // eslint-disable-next-line no-new
  new Clipboard('.summary-clipboard');
};
