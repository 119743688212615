import { initializeGeocoder, initializeSourceMarker, selectRegion } from './googlemaps';
import { loader } from './googlemaps-loader';

window.initializeLocationForm = () => {
  loader
    .load()
    .then(() => {
      initializeGeocoder('map-canvas');
      initializeSourceMarker(
        'location-latitude',
        'location-longitude',
        'location-civicNumber',
        'location-street',
        'location-city',
        'location-province',
        null,
        'location-position',
        'Canada',
      );
    })
    .catch((e) => {
      console.debug(e);
    });

  $('#location-region').change(() => selectRegion('location-region', 'location-city', 'location-province', '/app/api/region'));
};
