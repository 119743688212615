const sortingFunction = (option1, option2) => {
  if (option1.value === 'OTHER' && option2.value === 'OTHER') {
    return 0;
  }
  if (option1.value === 'OTHER') {
    return 1;
  }
  if (option2.value === 'OTHER') {
    return -1;
  }
  if (option1.text > option2.text) {
    return 1;
  }
  if (option1.text < option2.text) {
    return -1;
  }
  return 0;
};

// eslint-disable-next-line import/prefer-default-export
export const sortAlphabetically = (options) => {
  const textsAndValues = options
    .map((_, o) => {
      return { text: $(o).text(), value: o.value, selected: o.selected };
    })
    .get();
  textsAndValues.sort(sortingFunction);
  options.each((i, o) => {
    o.value = textsAndValues[i].value;
    o.selected = textsAndValues[i].selected;
    $(o).text(textsAndValues[i].text);
  });
};
window.sortAlphabetically = sortAlphabetically;
