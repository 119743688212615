import { registerSearchableSelector } from './theme';
import { initializePageRefresh } from './nez-rouge';

window.initializeTransportList = () => {
  registerSearchableSelector(
    'institution',
    'filter-institution',
    (term) => {
      return {
        name: term,
      };
    },
    (value) => {
      return {
        id: value.id,
        text: value.name,
      };
    },
  );

  initializePageRefresh(document);
};
