import { initializeGeocoder, initializeSourceMarker } from './googlemaps';
import { loader } from './googlemaps-loader';

window.initializeRegionForm = () => {
  loader
    .load()
    .then(() => {
      initializeGeocoder('map-canvas');
      initializeSourceMarker(
        'region-latitude',
        'region-longitude',
        null,
        null,
        'region-city',
        'region-province',
        null,
        'region-position',
        'Canada',
      );
    })
    .catch((e) => {
      console.debug(e);
    });
};
