/* eslint-disable no-use-before-define */

import debounce from 'lodash/debounce';

let geocoder;
let map;
let sourceMarker;
let sourceMarkerImage;
let targetMarker;
let targetMarkerImage;

let assignmentMarkers;
let teamMarkers;
let assignmentMarkerImage;
let assignmentVIPMarkerImage;
let transportRequestMarker;
let transportRequestMarkerImage;
let currentInfoWindow;
let currentInfoWindowGlobalMap;

let assignedTeamMarkerImage;
let inReturnTeamMarkerImage;
let availableTeamMarkerImage;
let onBreakMarkerImage;

export const initializeGeocoder = (canvasElementId) => {
  geocoder = new google.maps.Geocoder();
  const latlng = new google.maps.LatLng(46.81771, -71.229457);
  const mapOptions = {
    zoom: 11,
    center: latlng,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
  };
  const mapElement = document.getElementById(canvasElementId);
  map = new google.maps.Map(mapElement, mapOptions);
};

export const initializeAssignmentMap = () => {
  transportRequestMarker = [];
  assignmentMarkers = [];
  teamMarkers = [];
  currentInfoWindow = 0;
  currentInfoWindowGlobalMap = 0;

  assignmentVIPMarkerImage = new google.maps.MarkerImage('/img/map/yellow-car-marker.png');
  assignmentMarkerImage = new google.maps.MarkerImage('/img/map/blue-car-marker.png');
  transportRequestMarkerImage = new google.maps.MarkerImage('/img/map/purple-marker.png');
  assignedTeamMarkerImage = new google.maps.MarkerImage('/img/map/green-car-marker.png');
  inReturnTeamMarkerImage = new google.maps.MarkerImage('/img/map/orange-car-marker.png');
  availableTeamMarkerImage = new google.maps.MarkerImage('/img/map/blue-car-marker.png');
  onBreakMarkerImage = new google.maps.MarkerImage('/img/map/red-car-marker.png');
};

window.initializeAssignmentMap = initializeAssignmentMap;
window.initializeGeocoder = initializeGeocoder;

const getTeamMarkerImage = (team) => {
  if (team.type === 'VIP') {
    return assignmentVIPMarkerImage;
  }
  if (team.status === 'ASSIGNED') {
    return assignedTeamMarkerImage;
  }
  if (team.status === 'IN_RETURN') {
    return inReturnTeamMarkerImage;
  }
  if (team.status.includes('AVAILABLE')) {
    return availableTeamMarkerImage;
  }
  if (team.status === 'ON_BREAK') {
    return onBreakMarkerImage;
  }
  return assignmentMarkerImage;
};

export const initializeSourceMarker = (
  latitudeElementId,
  longitudeElementId,
  civicNumberElementId,
  streetElementId,
  cityElementId,
  provinceElementId,
  postalCodeElementId,
  positionButtonElementId,
  markerSuffix,
) => {
  sourceMarker = [];
  sourceMarkerImage = new google.maps.MarkerImage('/img/map/red-marker.png');
  initializeMarker(
    sourceMarker,
    sourceMarkerImage,
    latitudeElementId,
    longitudeElementId,
    civicNumberElementId,
    streetElementId,
    cityElementId,
    provinceElementId,
    postalCodeElementId,
    positionButtonElementId,
    markerSuffix,
  );
};
window.initializeSourceMarker = initializeSourceMarker;

export const initializeTargetMarker = (
  latitudeElementId,
  longitudeElementId,
  civicNumberElementId,
  streetElementId,
  cityElementId,
  provinceElementId,
  postalCodeElementId,
  positionButtonElementId,
  markerSuffix,
) => {
  targetMarker = [];
  targetMarkerImage = new google.maps.MarkerImage('/img/map/blue-marker.png');
  initializeMarker(
    targetMarker,
    targetMarkerImage,
    latitudeElementId,
    longitudeElementId,
    civicNumberElementId,
    streetElementId,
    cityElementId,
    provinceElementId,
    postalCodeElementId,
    positionButtonElementId,
    markerSuffix,
  );
};

const initializeMarker = (
  marker,
  markerImage,
  latitudeElementId,
  longitudeElementId,
  civicNumberElementId,
  streetElementId,
  cityElementId,
  provinceElementId,
  postalCodeElementId,
  positionButtonElementId,
  markerSuffix,
) => {
  bindMarker(
    marker,
    markerImage,
    $(`#${latitudeElementId}`),
    $(`#${longitudeElementId}`),
    $(`#${civicNumberElementId}`),
    $(`#${streetElementId}`),
    $(`#${cityElementId}`),
    $(`#${provinceElementId}`),
    $(`#${postalCodeElementId}`),
    $(`#${positionButtonElementId}`),
    markerSuffix,
  );
};

const bindMarker = (
  marker,
  markerImage,
  latitudeInput,
  longitudeInput,
  civicNumberInput,
  streetInput,
  cityInput,
  provinceInput,
  postalCodeInput,
  positionButton,
  markerSuffix,
) => {
  const refreshFunc = () => {
    setMarkerForAddress(
      marker,
      markerImage,
      latitudeInput,
      null,
      longitudeInput,
      null,
      getFullAddress(
        civicNumberInput.val(),
        streetInput.val(),
        cityInput.val(),
        provinceInput.val(),
        postalCodeInput.val(),
        markerSuffix,
      ),
    );
  };

  positionButton.click(refreshFunc);
  addCalculateCoordinatesOnChange(civicNumberInput, refreshFunc);
  addCalculateCoordinatesOnChange(streetInput, refreshFunc);
  addCalculateCoordinatesOnChange(cityInput, refreshFunc);
  addCalculateCoordinatesOnChange(provinceInput, refreshFunc);
  addCalculateCoordinatesOnChange(postalCodeInput, refreshFunc);

  setMarkerForAddress(
    marker,
    markerImage,
    latitudeInput,
    latitudeInput.val(),
    longitudeInput,
    longitudeInput.val(),
    getFullAddress(
      civicNumberInput.val(),
      streetInput.val(),
      cityInput.val(),
      provinceInput.val(),
      postalCodeInput.val(),
      markerSuffix,
    ),
  );
};

const addCalculateCoordinatesOnChange = (elementInput, refreshFunc) => {
  if (elementInput) {
    elementInput.change(refreshFunc);
  }
};

const setMarkerForAddress = (marker, markerImage, latitudeInput, latitude, longitudeInput, longitude, pFullAddress) => {
  let fullAddress = pFullAddress;
  if (!fullAddress) {
    fullAddress = getFullAddress(null, 'Québec Basse-Ville');
  }

  addMarker(marker, markerImage, latitudeInput, latitude, longitudeInput, longitude, fullAddress);
};

const geocode = debounce((latitudeInput, longitudeInput, marker, markerImage, markerAddress) => {
  geocoder.geocode(
    {
      address: markerAddress,
    },
    (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        latitudeInput.val(results[0].geometry.location.lat());
        longitudeInput.val(results[0].geometry.location.lng());
        setMarker(marker, markerImage, results[0].geometry.location, markerAddress);
      } else if (status === google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
        // eslint-disable-next-line no-alert
        alert(
          'Vous êtes au dessus de la limite de requêtes permises dans google maps. Veuillez contacter le support. You are over the query limit in google maps. Please contact support.',
        );
      }
    },
  );
}, 125);

const addMarker = (marker, markerImage, latitudeInput, latitude, longitudeInput, longitude, markerAddress) => {
  if (latitude && longitude) {
    const coordinates = new google.maps.LatLng(latitude, longitude);
    setMarker(marker, markerImage, coordinates, markerAddress);
  } else {
    geocode(latitudeInput, longitudeInput, marker, markerImage, markerAddress);
  }
};

const setMarker = (marker, markerImage, coordinates, markerAddress) => {
  removeMarker(marker);

  map.setCenter(coordinates);

  marker[0] = new google.maps.Marker({
    map,
    position: coordinates,
    icon: markerImage,
  });

  const localMarker = marker[0];
  const localMarkerAddress = markerAddress;

  google.maps.event.addListener(localMarker, 'click', () => {
    closeInfoWindow(localMarker);
    const infowindow = new google.maps.InfoWindow();
    infowindow.setContent(localMarkerAddress);
    infowindow.open(map, localMarker);
    localMarker.infowindow = infowindow;
  });
};

export const addTeamMarkers = (teams, transportRequest) => {
  addNextAssignmentMarker(-1, teams, transportRequest);
};

window.addTeamMarkers = addTeamMarkers;

export const addTeamMarkersOnly = (teams) => {
  const bounds = new google.maps.LatLngBounds();

  teams.forEach((team) => {
    addTeamMarkerWithColors(team);

    if (team.position != null) {
      const coordinates = new google.maps.LatLng(team.position.lat, team.position.lng);
      bounds.extend(coordinates);
    }
  });

  map.fitBounds(bounds);
  map.panToBounds(bounds);
};

export const addTransportRequestMarkersOnly = (transportRequests) => {
  const bounds = new google.maps.LatLngBounds();

  transportRequests.forEach((transportRequest) => {
    addTransportRequestMarkerGlobalMap(transportRequest);

    const coordinates = new google.maps.LatLng(transportRequest.coordinates.lat, transportRequest.coordinates.lng);
    bounds.extend(coordinates);
  });

  map.fitBounds(bounds);
  map.panToBounds(bounds);
};

const addNextAssignmentMarker = (index, teams, transportRequest) => {
  // eslint-disable-next-line no-param-reassign
  index += 1;
  if (index < teams.length) {
    const fullAddress = getFullAddress(
      teams[index].civicNumber,
      teams[index].street,
      teams[index].city,
      teams[index].province,
      null,
      'Canada',
    );
    addAssignmentMarkerFullAddress(index, teams, transportRequest, fullAddress);
  }
};

const addTeamMarkerWithColors = (team) => {
  if (team.position !== null && team.status !== 'DISSOLVED') {
    const markerId = `teamMarker${team.id}`;
    let coordinates = new google.maps.LatLng(team.position.lat, team.position.lng);
    const localMarkerImage = getTeamMarkerImage(team);
    const localMarker = new google.maps.Marker({
      id: markerId,
      map,
      position: coordinates,
      icon: localMarkerImage,
    });

    coordinates = getStringCoordinates(team.coordinates);
    teamMarkers.push([coordinates, localMarker, team, localMarkerImage]);

    google.maps.event.addListener(localMarker, 'click', () => {
      closeTeamInfoWindowGlobalMap();
      openTeamInfoWindowGlobalMap(localMarker, team);
    });
  }
};

const addTransportRequestMarkerGlobalMap = (transportRequest) => {
  const coordinates = new google.maps.LatLng(transportRequest.coordinates.lat, transportRequest.coordinates.lng);

  map.setCenter(coordinates);

  const localMarker = new google.maps.Marker({
    id: `transportRequest${transportRequest.id}`,
    map,
    position: coordinates,
    icon: transportRequestMarkerImage,
  });

  transportRequestMarker.push([coordinates, localMarker, transportRequest]);

  const origin = getFullAddress(transportRequest.street, null, transportRequest.city, transportRequest.province, null, 'Canada');
  const destination = getFullAddress(
    transportRequest.destinationStreet,
    null,
    transportRequest.destinationCity,
    transportRequest.destinationProvince,
    null,
    'Canada',
  );

  google.maps.event.addListener(localMarker, 'click', () => {
    closeInfoWindow(localMarker);
    openTransportRequestInfoWindowGlobalMap(localMarker, transportRequest, origin, destination);
  });
};

const addAssignmentMarkerFullAddress = (index, teams, transportRequest, markerAddress) => {
  const teamInfo = teams[index];
  const markerId = `teamMarker${teamInfo.teamId}`;

  const coordinates = new google.maps.LatLng(teamInfo.coordinates.lat, teamInfo.coordinates.lng);

  let localMarkerImage = assignmentMarkerImage;

  if (teamInfo.type === 'VIP') {
    localMarkerImage = assignmentVIPMarkerImage;
  }

  const localMarker = new google.maps.Marker({
    id: markerId,
    map,
    position: coordinates,
    icon: localMarkerImage,
  });

  assignmentMarkers.push([getStringCoordinates(teamInfo.coordinates), localMarker, teamInfo, transportRequest, localMarkerImage]);

  const localMarkerAddress = markerAddress;

  google.maps.event.addListener(localMarker, 'click', () => {
    closeTeamInfoWindow();
    openTeamInfoWindow(localMarker, localMarkerAddress, teamInfo, transportRequest);
    currentInfoWindow = index;
  });

  addNextAssignmentMarker(index, teams, transportRequest);
};

export const addTransportRequestMarker = (transportRequestInfo) => {
  const fullAddress = getFullAddress(
    transportRequestInfo.street,
    null,
    transportRequestInfo.city,
    transportRequestInfo.province,
    null,
    'Canada',
  );
  addTransportRequestMarkerFullAddress(transportRequestMarker, transportRequestMarkerImage, fullAddress, transportRequestInfo);
};
window.addTransportRequestMarker = addTransportRequestMarker;

const addTransportRequestMarkerFullAddress = (marker, markerImage, markerAddress, transportRequestInfo) => {
  const coordinates = new google.maps.LatLng(transportRequestInfo.coordinates.lat, transportRequestInfo.coordinates.lng);

  map.setCenter(coordinates);

  // eslint-disable-next-line no-param-reassign
  marker[0] = new google.maps.Marker({
    map,
    position: coordinates,
    icon: markerImage,
  });

  const localMarker = marker[0];
  const localMarkerAddress = markerAddress;

  google.maps.event.addListener(localMarker, 'click', () => {
    closeInfoWindow(localMarker);
    openTransportRequestInfoWindow(localMarker, localMarkerAddress, transportRequestInfo);
  });
};

const incrementCurrentInfoIndex = () => {
  currentInfoWindow += 1;
  if (currentInfoWindow === assignmentMarkers.length) {
    currentInfoWindow = 0;
  }
};

const closeTeamInfoWindow = () => {
  const currentMarker = assignmentMarkers[currentInfoWindow][1];
  closeInfoWindow(currentMarker);
};

const closeTeamInfoWindowGlobalMap = () => {
  const currentMarker = teamMarkers[currentInfoWindowGlobalMap][1];
  closeInfoWindow(currentMarker);
};

const closeInfoWindow = (marker) => {
  if (marker.infowindow) {
    marker.infowindow.close();
  }
};

export const nextTeamInfoWindow = () => {
  closeTeamInfoWindow();
  pushBackMarkerZIndex();

  incrementCurrentInfoIndex();
  const markerAddress = assignmentMarkers[currentInfoWindow][0];
  const currentMarker = assignmentMarkers[currentInfoWindow][1];
  const teamInfo = assignmentMarkers[currentInfoWindow][2];
  const transportRequest = assignmentMarkers[currentInfoWindow][3];

  openTeamInfoWindow(currentMarker, markerAddress, teamInfo, transportRequest);
};
window.nextTeamInfoWindow = nextTeamInfoWindow;

const openTransportRequestInfoWindow = (marker, markerAddress, transportRequestInfo) => {
  const infowindow = new google.maps.InfoWindow();
  // eslint-disable-next-line no-param-reassign
  marker.infowindow = infowindow;

  map.setCenter(marker.getPosition());

  infowindow.setContent(getAssignmentTransportRequestInfoWindowContent(markerAddress, transportRequestInfo));
  infowindow.open(map, marker);
};

const openTransportRequestInfoWindowGlobalMap = (marker, transportRequestInfo, origin, destination) => {
  const infowindow = new google.maps.InfoWindow();
  // eslint-disable-next-line no-param-reassign
  marker.infowindow = infowindow;

  map.setCenter(marker.getPosition());

  infowindow.setContent(getAssignmentTransportRequestInfoWindowContentGlobalMap(transportRequestInfo, origin, destination));
  infowindow.open(map, marker);
};

const openTeamInfoWindow = (currentMarker, markerAddress, teamInfo, transportRequest) => {
  const infowindow = new google.maps.InfoWindow();
  // eslint-disable-next-line no-param-reassign
  currentMarker.infowindow = infowindow;
  currentMarker.setZIndex(99);

  map.setCenter(currentMarker.getPosition());

  infowindow.setContent(getAssignmentTeamInfoWindowContent(markerAddress, teamInfo, transportRequest));
  infowindow.open(map, currentMarker);
};

const openTeamInfoWindowGlobalMap = (currentMarker, teamInfo) => {
  const infowindow = new google.maps.InfoWindow();
  // eslint-disable-next-line no-param-reassign
  currentMarker.infowindow = infowindow;
  currentMarker.setZIndex(99);

  map.setCenter(currentMarker.getPosition());

  infowindow.setContent(getTeamInfoContent(teamInfo));
  infowindow.open(map, currentMarker);
};

const getTeamInfoContent = (team) => {
  let infoWindowContent = $('#info-window-team-template').html();

  infoWindowContent = infoWindowContent.replace(/\{TEAM_ID\}/g, team.id);
  infoWindowContent = infoWindowContent.replace(/\{TEAM_NUMBER_KEY\}/g, team.number === null ? '' : team.number);

  infoWindowContent = infoWindowContent.replace(/\{VIP_TEAM_DISPLAY\}/g, team.type === 'VIP' ? 'inline' : 'none');
  infoWindowContent = infoWindowContent.replace(/\{TEAM_DISPLAY\}/g, team.type !== 'VIP' ? 'inline' : 'none');
  infoWindowContent = infoWindowContent.replace(/\{RADIO_BASE\}/g, team.radioBase === null ? '' : team.radioBase);

  infoWindowContent = infoWindowContent.replace(/\{MOBILE_PHONE_DISPLAY\}/g, team.useMobilePhone ? 'block' : 'none');
  infoWindowContent = infoWindowContent.replace(/\{MOBILE_PHONE\}/g, team.mobilePhone === null ? '' : team.mobilePhone.value);

  infoWindowContent = infoWindowContent.replace(/\{RADIO_DISPLAY\}/g, team.useRadio || team.useAmateurRadio ? 'block' : 'none');
  infoWindowContent = infoWindowContent.replace(
    /\{AMATEUR_RADIO_BASE\}/g,
    team.amateurRadioBase === null ? '' : team.amateurRadioBase,
  );

  infoWindowContent = infoWindowContent.replace(
    /\{SPECIAL_CODES_DISPLAY\}/g,
    team.specialCodes != null && team.specialCodes.length > 0 ? 'block' : 'none',
  );
  infoWindowContent = infoWindowContent.replace(/\{SPECIAL_CODES\}/g, team.specialCodes === null ? '' : team.specialCodes);

  return infoWindowContent;
};

const getAssignmentTransportRequestInfoWindowContent = (address, transportRequestInfo) => {
  let infoWindowContent = $('#info-window-request-template').html();
  infoWindowContent = infoWindowContent.replace(/\{ADDRESS\}/g, address);
  infoWindowContent = infoWindowContent.replace(/\{IS_MANUAL_DISPLAY\}/g, transportRequestInfo.manual ? 'inline' : 'none');
  infoWindowContent = infoWindowContent.replace(/\{IS_AUTOMATIC_DISPLAY\}/g, !transportRequestInfo.manual ? 'inline' : 'none');

  return infoWindowContent;
};

const getAssignmentTransportRequestInfoWindowContentGlobalMap = (transportRequestInfo, origin, destination) => {
  let infoWindowContent = $('#info-window-request-template').html();

  infoWindowContent = infoWindowContent.replace(/\{ORIGIN\}/g, origin);
  infoWindowContent = infoWindowContent.replace(/\{DESTINATION\}/g, destination);

  infoWindowContent = infoWindowContent.replace(/\{FIRSTNAME\}/g, transportRequestInfo.firstName);
  infoWindowContent = infoWindowContent.replace(/\{LASTNAME\}/g, transportRequestInfo.lastName);

  infoWindowContent = infoWindowContent.replace(/\{MANUFACTURER\}/g, transportRequestInfo.vehicleManufacturer);
  infoWindowContent = infoWindowContent.replace(/\{MODEL\}/g, transportRequestInfo.vehicleModel);

  infoWindowContent = infoWindowContent.replace(/\{TRANSPORT_REQUEST_ID\}/g, transportRequestInfo.transportRequestId);

  return infoWindowContent;
};

const getAssignmentTeamInfoWindowContent = (address, teamInfo, transportRequest) => {
  let infoWindowContent = $('#info-window-team-template').html();
  infoWindowContent = infoWindowContent.replace(/\{ADDRESS\}/g, address);
  infoWindowContent = infoWindowContent.replace(/\{TEAM_ID\}/g, teamInfo.teamId);
  infoWindowContent = infoWindowContent.replace(/\{TEAM_NUMBER_KEY\}/g, teamInfo.teamNumber);
  infoWindowContent = infoWindowContent.replace(/\{TRANSPORT_REQUEST_ID\}/g, transportRequest.transportRequestId);

  infoWindowContent = infoWindowContent.replace(/\{VIP_TEAM_DISPLAY\}/g, teamInfo.type === 'VIP' ? 'inline' : 'none');
  infoWindowContent = infoWindowContent.replace(/\{TEAM_DISPLAY\}/g, teamInfo.type !== 'VIP' ? 'inline' : 'none');
  infoWindowContent = infoWindowContent.replace(/\{RADIO_BASE\}/g, teamInfo.radioBase);

  infoWindowContent = infoWindowContent.replace(/\{PHONE_CABIN_DISPLAY\}/g, teamInfo.usePhoneCabin ? 'block' : 'none');

  infoWindowContent = infoWindowContent.replace(/\{MOBILE_PHONE_DISPLAY\}/g, teamInfo.useMobilePhone ? 'block' : 'none');
  infoWindowContent = infoWindowContent.replace(/\{MOBILE_PHONE\}/g, teamInfo.mobilePhone);

  infoWindowContent = infoWindowContent.replace(
    /\{RADIO_DISPLAY\}/g,
    teamInfo.useRadio || teamInfo.useAmateurRadio ? 'block' : 'none',
  );
  infoWindowContent = infoWindowContent.replace(/\{AMATEUR_RADIO_BASE\}/g, teamInfo.amateurRadioBase);

  infoWindowContent = infoWindowContent.replace(
    /\{SPECIAL_CODES_DISPLAY\}/g,
    teamInfo.specialCodes != null && teamInfo.specialCodes.length > 0 ? 'block' : 'none',
  );
  infoWindowContent = infoWindowContent.replace(/\{SPECIAL_CODES\}/g, teamInfo.specialCodes);

  infoWindowContent = infoWindowContent.replace(/\{AMATEUR_RADIO_BASE\}/g, teamInfo.amateurRadioBase);

  infoWindowContent = infoWindowContent.replace(/\{IS_MANUAL_DISPLAY\}/g, teamInfo.manual ? 'inline' : 'none');
  infoWindowContent = infoWindowContent.replace(/\{IS_AUTOMATIC_DISPLAY\}/g, !teamInfo.manual ? 'inline' : 'none');

  return infoWindowContent;
};

const removeMarker = (marker) => {
  if (marker.length > 0) {
    marker[0].setMap(null);
  }
};

export const selectRegion = (regionElementId, cityElementId, provinceElementId, restControllerUrl) => {
  if ($(`#${regionElementId}`).val() !== '') {
    $.ajax({
      url: `${restControllerUrl}/${$(`#${regionElementId}`).val()}`,
      dataType: 'json',
      success(data) {
        $(`#${cityElementId}`).val(data.city).change();
        $(`#${provinceElementId}`).val(data.province).change();
      },
    });
  } else {
    $(`#${cityElementId}`).val('').change();
    $(`#${provinceElementId}`).val('').change();
  }
};
window.selectRegion = selectRegion;

const getFullAddress = (civicNumber, street, city, province, postalCode, markerSuffix) => {
  let joinedAddress = '';
  if (civicNumber || street || city || province || postalCode) {
    joinedAddress = appendCommaSeparated('', civicNumber);
    joinedAddress = appendWithSeparater(joinedAddress, street, ' ');
    joinedAddress = appendCommaSeparated(joinedAddress, city);

    joinedAddress = appendCommaSeparated(joinedAddress, province);
    joinedAddress = appendCommaSeparated(joinedAddress, postalCode);
    joinedAddress = appendCommaSeparated(joinedAddress, markerSuffix);
  }
  return joinedAddress;
};

const getStringCoordinates = (coordinate) => {
  let joinedAddress = '';
  if (coordinate) {
    joinedAddress = appendWithSeparater(joinedAddress, coordinate.lat, '');
    joinedAddress = appendWithSeparater(joinedAddress, coordinate.lng, ' ');
  }
  return joinedAddress;
};

const appendCommaSeparated = (buffer, valueToAppend) => appendWithSeparater(buffer, valueToAppend, ', ');

const appendWithSeparater = (buffer, valueToAppend, separater) => {
  if (valueToAppend) {
    if (buffer.length > 0) {
      // eslint-disable-next-line no-param-reassign
      buffer += separater;
    }
    // eslint-disable-next-line no-param-reassign
    buffer += valueToAppend;
  }
  return buffer;
};

const pushBackMarkerZIndex = () => {
  const currentMarker = assignmentMarkers[currentInfoWindow][1];
  currentMarker.setZIndex(98);
};

// this function is used by selenium tests
export const getMapMarkerLatitude = () => this.marker.getPosition().lat();
window.getMapMarkerLatitude = getMapMarkerLatitude;

// this function is used by selenium tests
export const getMapMarkerLongitude = () => this.marker.getPosition().lng();
window.getMapMarkerLongitude = getMapMarkerLongitude;

// this function is used by the assignment team list and selenium tests
export const clickTeamMarker = (teamId) => {
  assignmentMarkers.forEach((assignmentMarker) => {
    if (assignmentMarker[2].teamId === teamId) {
      google.maps.event.trigger(assignmentMarker[1], 'click');
    }
  });
};
window.clickTeamMarker = clickTeamMarker;
